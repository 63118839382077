var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("ExamSessionsWidget", {
        ref: "ExamSessionsWidget",
        attrs: { examSessions: _vm.examSessions, loading: _vm.loading },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }