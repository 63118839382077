<template>
  <div class="root">
    <ExamSessionsWidget :examSessions="examSessions" :loading="loading" ref="ExamSessionsWidget" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service.js'
import ExamSessionsWidget from '@/view/pages/dashboard_widgets/ExamSessionsWidget.vue'

export default {
  name: 'sinav-sayfasi',
  components: { ExamSessionsWidget },
  data() {
    return {
      examSessions: [],
      examSessionStudent: {},
      examSessionStudentId: 0,
      count: 5,
      loading: true,
      options: {},
      ExamId: this.$route.params.id,
      dialog: false,
      ExamSessionId: 0
    }
  },
  filters: {},
  mounted() {
    this.getDataFromApi()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Sınav ' + this.ExamId }])
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.examSessionStudent = {}
      this.examSessionStudentId = 0
      this.dialog = false
    },
    getExamSessionStudentFromApi() {
      ApiService.setHeader()
      ApiService.get('api/StudentExam/ExamSessionStudent/' + this.ExamSessionId)
        .then((data) => {
          this.examSessionStudent = data.data
          this.examSessionStudentId = this.examSessionStudent.Id
          this.dialog = true
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentExam/ExamSession', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        Query: {
          ExamId: this.ExamId
        }
      })
        .then((data) => {
          this.examSessions = data.data.Results
          this.totalRows = data.data.TotalNumberOfRecords
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
